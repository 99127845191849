import './modules/helpers';
import start from './modules/router';
import mobileNav from './modules/mobileNav';
import wow from './modules/wow';
import slick from './modules/slick';
import fixedMenu from './modules/fixedMenu';
import subMenu from './modules/subMenu';
import scroller from './modules/scroller';
import mobileFixes from './modules/mobileFixes';

fixedMenu();
mobileNav();
//wow();
slick();
subMenu();
scroller();
mobileFixes();