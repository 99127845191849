import $ from 'jquery';

export default function () {

	var $btn = $('.js-open-sub-menu > a');

	$btn.on('click', function(event) {
		event.preventDefault();
		$('.js-open-sub-menu').toggleClass('active');
	});
}