import $ from 'jquery';

export default function () {

	var fixedFrom = $('header').outerHeight() + $('.c-banner-interne').outerHeight() + $('#c-section-separator').height();

	$(window).on('resize', () => {
		fixedFrom = $('header').outerHeight() + $('.c-banner-interne').outerHeight() + $('#c-section-separator').height();
	});
	
	$(window).on('scroll load resize', () => {
		
		if($(window).scrollTop() > fixedFrom){
			$('.c-inner-nav').addClass('fixed');
		} else {
			$('.c-inner-nav').removeClass('fixed');
		}

	});
}