import $ from 'jquery';
import Slick from 'slick-carousel';

export default function () {

	if($('.fn-home-slider').length) {
		$('.fn-home-slider').slick({
			infinite: true,
  			slidesToShow: 1,
  			vertical: true,
  			dots: true,
  			arrows: false,
  			centerMode: false,
  			autoplay: true,
  			autoplaySpeed: 5000,
        pauseOnHover: false
		});
	}

	if($('.js-slider-images').length > 0) {
		
		$('.js-slider-images').slick({
			infinite: true,
  			slidesToShow: 1,
  			arrows: true,
  			asNavFor: $('.js-slider-menu'),
  			focusOnSelect: true
		});

		$('.js-slider-menu').slick({
			infinite: true,
  			slidesToShow: 3,
  			arrows: false,
  			centerMode: true,
  			asNavFor: $('.js-slider-images'),
  			focusOnSelect: true,

		});

	}
}